import React from "react"
import { graphql } from "gatsby"
import Layout from "../../layout/layout";
import Header from '../../components/header/header'
import LandingpageVagas from "../../components/landingpagevagas/landingpagevagas";
import './vaga.template.scss';
import Contato from '../../components/contato1/contato1'

export default function Template({data}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const contact = {
    title: "Como se candidatar:",
    content: `Para se candidatar a essa vaga envie um email para <strong>${frontmatter.email}</strong> com o seu currículo e os seus horários disponíveis para estágio com o assunto: <strong style="text-transform: uppercase;">${frontmatter.title}</strong>. Caso você esteja se candidatando para mais de uma vaga, por favor adicione as outras vagas ao assunto do e-mail da seguinte forma: <strong  style="text-transform: uppercase;">Estágio VAGA1(ex: backend), VAGA2...</strong>`
  }

  const headerData = {
    gaID: "UA-158683343",
    keyWords: "tecnologia, são carlos",
    gtmID: 'GTM-NZ653HT',
    pageTitle: "SF Labs | Desenvolvimento de tecnologia | São Carlos",
    descricao: "A SF Labs desenvolve tecnologia que forneça novas possibilidades para expandir os horizontes do seu negócio."
  }
  
  return (
    <div>
      <Header headerData={headerData}></Header>
      <Layout>
        <LandingpageVagas texto={frontmatter.title} bgimage={frontmatter.imgSrc}></LandingpageVagas>
        <section lang="pt-br">
          <div className="container">
            <div className="vagas-content">
              <h2>Aplicar até: {frontmatter.date}</h2>
              <br></br>
              <div className="content"
                dangerouslySetInnerHTML={{ __html: html }}
              />
              <div className="contact-instructions">
                <h2 className="contact-label">{contact.title}</h2>
                <p className="contact-p" dangerouslySetInnerHTML={{ __html: contact.content }}></p>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </div>

  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "DD MMMM, YYYY", locale: "pt-br")
        path
        title
        imgSrc
        email
      }
    }
  }
`
import React from 'react';
import { StaticQuery, graphql } from 'gatsby'
import './landingpagevagas.scss';
import Img from "gatsby-image"

const credits = "Photos on Unsplash"
const LandingpageVagas = ({ texto, bgimage, images }) => {
  const img = getImage(images, bgimage || 'frontend')[0]
  return (
    <div id="home">
      <div className="vagas-capa">
        <Img className="bg-image" fluid={img.node.fluid}></Img>
        <div className="home-content">
          <p id="credit-capa" dangerouslySetInnerHTML={{ __html: `<!--${credits}-->` }}></p>
          <div className="vagas-titulo-capa-container">
            <h1 className="vagas-titulo-capa is-large">{texto}</h1>
          </div>
        </div>
      </div>
    </div>
  )
}

const getImage = (images, bgimage) => images
  .filter(edge => edge
    .node.original.src
    .search(bgimage) != -1
  )

export default props => (
  <StaticQuery
    query={graphql`
    {
      images: allImageSharp(filter: {original: {src: {regex: "/.*lp.*/"}}}) {
        edges {
          node {
            id
            original {
              width
              height
              src
            }
            fluid(maxWidth: 1900) {
              base64
              tracedSVG
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              originalImg
              originalName
              presentationWidth
              presentationHeight
            }
          }
        }
      }
    }
    `}
    render={data =>
      <LandingpageVagas
        images={data.images.edges}
        {...props}
      >
      </LandingpageVagas>}
  />
)